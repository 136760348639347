import clsx from 'clsx';
import { ReactElement } from 'react';
import { Text } from '@/components';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import useProfile from '../../hooks/useProfile';

export type ProfileHeaderExtrasProps = StyleProps & {
  username: string;
};

const ROOT = makeRootClassName('ProfileHeaderExtras');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {} as const;

const formatUserReportsStats = (stat = 0) => {
  if (stat < 0) {
    return `-${String(Math.abs(stat)).padStart(4, '0')}`;
  }
  return String(stat).padStart(4, '0');
};

function ProfileHeaderExtras(props: ProfileHeaderExtrasProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };
  const { user, loading } = useProfile({ username: p.username });

  if (loading) {
    return <></>;
  }

  return (
    <div className={clsx(ROOT, p.className)}>
      <div className={el`achievements-wrapper`}>
        <div className={el`scores-wrapper`}>
          <div className={el`score-box`}>
            <Text
              className={clsx(el`score`, el`score-highlighted`)}
              type="custom"
              as="h3"
            >
              {formatUserReportsStats(user?.reportsFiledCount)}
            </Text>
            <Text
              className={clsx(el`score-label`, el`score-label-highlighted`)}
              type="body-sm"
            >
              Reports
            </Text>
          </div>
          <div className={el`score-box`}>
            <Text className={el`score`} type="custom" as="h3">
              {formatUserReportsStats(user?.reportCommentsGivenCount)}
            </Text>
            <Text className={el`score-label`} type="body-sm">
              Comments
            </Text>
          </div>
          <div className={el`score-box`}>
            <Text className={el`score`} type="custom" as="h3">
              {formatUserReportsStats(user?.reportVotesGivenCount)}
            </Text>
            <Text className={el`score-label`} type="body-sm">
              Upvotes
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileHeaderExtras;
