import clsx from 'clsx';
import { useRouter } from 'next/router';
import React, { ReactElement } from 'react';
import { PartnerLogo, Text } from '@/components';
import { SubdomainPageProps } from '@/types/page';
import { NOT_FOUND_ROUTE } from '@/types/routes';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import useOrganization from './hooks/useOrganization';

export type SubdomainHeaderProps = StyleProps & {
  name?: string;
  imageUrl?: string;
  description?: string;
};

const ROOT = makeRootClassName('SubdomainHeader');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {} as const;

export function SubdomainHeader(props: SubdomainHeaderProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  return (
    <div className={clsx(ROOT, p.className)}>
      {p.imageUrl && <PartnerLogo name={p.name} src={p.imageUrl} />}

      {p.description && (
        <Text className={el`description`}>{p.description}</Text>
      )}
    </div>
  );
}
function SubdomainHeaderContainer(
  props: Pick<SubdomainPageProps, 'orgId' | 'orgSlug'>
): ReactElement {
  const { push } = useRouter();
  const { organization, loading } = useOrganization({
    orgId: props.orgId,
    orgSlug: props.orgSlug,
  });

  if (!loading && !organization) {
    push(NOT_FOUND_ROUTE);
    return <></>;
  }

  if (organization && !organization.isSubdomainViewable) {
    push(NOT_FOUND_ROUTE);
    return <></>;
  }

  return (
    <SubdomainHeader
      name={organization?.name ?? undefined}
      imageUrl={organization?.imageUrl ?? undefined}
      description={organization?.description ?? undefined}
    />
  );
}

export default SubdomainHeaderContainer;
