import {
  GetOrganizationQuery,
  useGetOrganizationQuery,
} from '@/generated/graphql';

type UseOrganizationOptions = {
  orgId?: string;
  orgSlug?: string;
};

type UseOrganizationState = {
  organization?: GetOrganizationQuery['organization']['organization'];
  loading: boolean;
};

type UseOrganizationActions = {
  // add any actions the hook provides
};

export type UseOrganizationValue = UseOrganizationState &
  UseOrganizationActions;

const useOrganization = (
  props: UseOrganizationOptions
): UseOrganizationValue => {
  const { data, loading } = useGetOrganizationQuery({
    variables: {
      input: {
        id: props.orgId,
        slug: props.orgSlug,
      },
    },
  });

  return {
    organization: data?.organization.organization,
    loading,
  };
};

export default useOrganization;
