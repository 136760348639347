import { sanitizeUrl } from '@braintree/sanitize-url';
import clsx from 'clsx';
import React, { ReactElement } from 'react';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';

export type PartnerLogoProps = StyleProps & {
  /**
   * The relative image link
   * Partner images are stored in /images/partners/[imagename].svg
   */
  src: string;
  name?: string;
};

const ROOT = makeRootClassName('PartnerLogo');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {
  variant: 'header',
} as const;

function PartnerLogo(props: PartnerLogoProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  return (
    <div className={clsx(ROOT, `variant-${p.variant}`, p.className)}>
      <img
        src={sanitizeUrl(p.src)}
        alt={p.name ? `${name} logo` : 'Partner Logo'}
      />
    </div>
  );
}

export default PartnerLogo;
