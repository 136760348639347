import { MyProfileQueryResult, useMyProfileQuery } from '@/generated/graphql';

type UseMyProfile = {
  myProfile: NonNullable<MyProfileQueryResult['data']>['me'] | null;
  loading: boolean;
};

const useMyProfile = (): UseMyProfile => {
  const { data, loading } = useMyProfileQuery();
  return {
    myProfile: data?.me ?? null,
    loading,
  };
};

export default useMyProfile;
