import { useState } from 'react';
import { useMe } from '@/hooks';
import { useUploads } from '@/hooks/uploads';
import { UploadableEntity } from '@/generated/graphql';

type UseUploadProfilePictureState = {
  isUploading: boolean;
  url?: string;
};
type UseUploadProfilePictureActions = {
  onSelectFile: (file: File) => void;
};
type UseUploadProfilePictureValue = UseUploadProfilePictureState &
  UseUploadProfilePictureActions;

const useUploadProfilePicture = (): UseUploadProfilePictureValue => {
  const { me } = useMe();
  const [mostRecentUploadId, setMostRecentUploadId] = useState<string>();
  const { uploads, addFileToUpload, isLoading } = useUploads({
    subjectType: UploadableEntity.PROFILE_PHOTO_UPLOAD,
  });

  const onSelectFile = (file: File) => {
    const upload = addFileToUpload(file);
    setMostRecentUploadId(upload.uuid);
  };

  const isUploading = isLoading(mostRecentUploadId ?? '');
  const url =
    uploads.find((upload) => upload.uuid === mostRecentUploadId)?.uploadUrl ??
    me?.photo?.url;

  return { onSelectFile, url, isUploading };
};

export default useUploadProfilePicture;
