import { PageLoadingProps, PageProps, PageType } from '@/types/page';
import {
  getAddressFromDynamicRoute,
  getAddressFromQueryParams,
  getCategoryFromDynamicRoute,
  getCategoryFromQueryParams,
  getChainFromDynamicRoute,
  getChainFromQueryParams,
  getDomainFromDynamicRoute,
  getDomainFromQueryParams,
  getReportIdFromQueryParams,
  getReportSupportKindFromQueryParams,
  getSupportPartnerFromDynamicRoute,
} from './../types/routes';
import type { ParsedUrlQuery } from 'querystring';
/**
 * This function gets the page variables from the base dynamic route i.e.
 * /browse/:chain or /org/:orgId
 */
export const getPagePropsFromDynamicRoute = ({
  query,
  pageType,
  context,
}: {
  query: ParsedUrlQuery;
  pageType: PageType;
  context?: PageType;
}): PageProps | PageLoadingProps => {
  switch (pageType) {
    case PageType.BROWSE_CHAIN: {
      const chain = getChainFromDynamicRoute(query);
      if (!chain) {
        return {
          isLoading: true,
        };
      }
      return {
        pageType,
        chain,
      };
    }
    case PageType.BROWSE_CATEGORY: {
      const category = getCategoryFromDynamicRoute(query);
      if (!category) {
        return {
          isLoading: true,
        };
      }
      return {
        pageType,
        category,
      };
    }
    case PageType.SEARCH_ADDRESS: {
      const address = getAddressFromDynamicRoute(query);
      const chain = getChainFromQueryParams(query); // retrieve from query params, if exists
      if (!address) {
        return {
          isLoading: true,
        };
      }
      return { pageType, address, chain };
    }
    case PageType.SEARCH_DOMAIN: {
      const domain = getDomainFromDynamicRoute(query);
      if (!domain) {
        return {
          isLoading: true,
        };
      }
      return { pageType, domain };
    }
    case PageType.PROFILE:
    case PageType.PROFILE_SELF:
    case PageType.PROFILE_OTHER: {
      const { usernameSlug } = query;
      if (Array.isArray(usernameSlug) || !usernameSlug) {
        return {
          isLoading: true,
        };
      }
      return { pageType, username: usernameSlug };
    }
    case PageType.REPORT_BY_ID: {
      const { reportId } = query;
      if (Array.isArray(reportId) || !reportId) {
        return {
          isLoading: true,
        };
      }

      if (!context) {
        return { pageType, reportId };
      }

      const reportProps = getPagePropsFromQueryParams({
        reportId,
        query,
        context,
      });

      return reportProps;
    }
    case PageType.EDIT_REPORT: {
      const { reportId } = query;
      if (Array.isArray(reportId) || !reportId) {
        return {
          isLoading: true,
        };
      }
      return { pageType, reportId };
    }
    case PageType.MY_FEED: {
      const { reportId, orgId } = query;
      if (Array.isArray(reportId) || Array.isArray(orgId) || !orgId) {
        return {
          isLoading: true,
        };
      }
      return { pageType, reportId, orgId };
    }
    case PageType.SUBDOMAIN_FEED: {
      const { orgId, orgSlug } = query;
      if (
        Array.isArray(orgId) ||
        Array.isArray(orgSlug) ||
        (!orgId && !orgSlug)
      ) {
        return {
          isLoading: true,
        };
      }

      return { pageType, orgId, orgSlug };
    }
    case PageType.SUPPORT_PARTNER_OPT_IN: {
      const partner = getSupportPartnerFromDynamicRoute(query);
      const reportId = getReportIdFromQueryParams(query);
      const kind = getReportSupportKindFromQueryParams(query);

      if (!partner || !reportId) {
        return {
          isLoading: true,
        };
      }

      return {
        pageType,
        partnerSlug: partner,
        reportId,
        kind,
      };
    }
    case PageType.HOME:
    case PageType.BROWSE_ALL:
    case PageType.FILE_REPORT:
    case PageType.ABOUT:
    case PageType.FAQ:
    case PageType.LEADERBOARD:
    case PageType.GLOSSARY:
    case PageType.CONTACT:
      return { pageType };
  }
};

/**
 * Util to use when you need to grab header props from the query
 * params. This is useful when you need to render a page header
 * for the single report page that looks like it's embedded on
 * another page.
 */
export const getPagePropsFromQueryParams = ({
  query,
  context,
  reportId,
}: {
  query: ParsedUrlQuery;
  context: PageType;
  reportId?: string;
}): PageProps | PageLoadingProps => {
  if (!query) {
    return { isLoading: true };
  }

  if (!context && reportId) {
    return { pageType: PageType.REPORT_BY_ID, reportId };
  }

  switch (context) {
    case PageType.BROWSE_CHAIN: {
      const chain = getChainFromQueryParams(query);
      if (!chain) {
        return {
          isLoading: true,
        };
      }
      return {
        pageType: context,
        chain,
      };
    }
    case PageType.BROWSE_CATEGORY: {
      const category = getCategoryFromQueryParams(query);
      if (!category) {
        return {
          isLoading: true,
        };
      }
      return {
        pageType: context,
        category,
      };
    }
    case PageType.SEARCH_ADDRESS: {
      const address = getAddressFromQueryParams(query);
      const chain = getChainFromQueryParams(query);
      if (!address) {
        return {
          isLoading: true,
        };
      }
      return { pageType: context, address, chain };
    }
    case PageType.SEARCH_DOMAIN: {
      const domain = getDomainFromQueryParams(query);
      if (!domain) {
        return {
          isLoading: true,
        };
      }
      return { pageType: context, domain };
    }
    case PageType.PROFILE:
    case PageType.PROFILE_SELF:
    case PageType.PROFILE_OTHER: {
      const { username: usernameParam } = query;
      if (Array.isArray(usernameParam) || !usernameParam) {
        return {
          isLoading: true,
        };
      }
      return { pageType: context, username: usernameParam };
    }
    case PageType.MY_FEED: {
      const { org: orgParam } = query;
      if (Array.isArray(orgParam) || !orgParam) {
        return {
          isLoading: true,
        };
      }
      return { pageType: context, reportId, orgId: orgParam };
    }
    case PageType.SUBDOMAIN_FEED: {
      const { org: orgParam, orgSlug } = query;
      if (
        Array.isArray(orgParam) ||
        Array.isArray(orgSlug) ||
        (!orgParam && !orgSlug)
      ) {
        return {
          isLoading: true,
        };
      }

      return { pageType: context, orgId: orgParam, orgSlug };
    }
    case PageType.SUPPORT_PARTNER_OPT_IN: {
      const partner = getSupportPartnerFromDynamicRoute(query);
      const reportId = getReportIdFromQueryParams(query);
      const kind = getReportSupportKindFromQueryParams(query);

      if (!partner || !reportId) {
        return {
          isLoading: true,
        };
      }

      return {
        pageType: context,
        partnerSlug: partner,
        reportId,
        kind,
      };
    }
    default:
      return { pageType: context, reportId: reportId ?? '' };
  }
};
