import { useContext } from 'react';
import MyFeedContext from './context';
import { MyFeedContextValue } from './provider';

export default function useMyFeedContext(): MyFeedContextValue {
  const context = useContext(MyFeedContext);
  if (!context) {
    throw new Error(
      "No MyFeedContext available. You must use this hook in a component that's a descendent of MyFeedProvider"
    );
  }

  return context;
}
