import clsx from 'clsx';
import { format } from 'date-fns';
import { ReactElement } from 'react';
import { svgCheckmark } from '@/assets/icons';
import {
  Avatar,
  Icon,
  InteractableComponent,
  ResponsiveText,
  Text,
  UploadAvatar,
} from '@/components';
import { SocialInfo } from '@/features/edit-profile/types';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import useMyProfile from '../../hooks/useMyProfile';
import useProfile from '../../hooks/useProfile';
import useUploadProfilePicture from '../../hooks/useUploadProfilePicture';
import { SocialHandles } from '../social-handles';

export type ProfileHeaderProps = StyleProps & {
  /** Whether the profile header is for the user  or someone else */
  isMe: boolean;
  /** The username of the profile page */
  username: string;
  /** Whether the user is trusted */
  trusted?: boolean;
  /** The avatar image src, if it exists */
  avatarSrc?: string;
  /** Whether the user has agreed to be contacted */
  agreedToBeContacted: boolean;
  /** List of social media handles for the user */
  socialInfos?: SocialInfo[];

  /** The date the user created their account */
  joinedAt?: Date;

  /**
   * Handler to call when the user selects a new image file as a profile
   * picture.
   * */
  onSelectFile?: (file: File) => void;
};

const ROOT = makeRootClassName('ProfileHeader');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {} as const;

export function ProfileHeader(props: ProfileHeaderProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  return (
    <div className={clsx(ROOT, p.className)}>
      <div className={el`user-details-wrapper`}>
        {p.isMe ? (
          <UploadAvatar
            className={el`avatar`}
            image={p.avatarSrc ?? '/images/avatar.svg'}
            onSelectFile={p.onSelectFile}
            size="3xl"
          />
        ) : (
          <Avatar
            className={el`avatar`}
            image={p.avatarSrc ?? '/images/avatar.svg'}
            size="3xl"
          />
        )}
        <div className={el`user-details-text`}>
          <ResponsiveText type="h3" tabletType="h2" className={el`username`}>
            {props.username}
            {p.trusted && (
              <InteractableComponent className={el`trusted-icon-wrapper`}>
                <Icon
                  content={svgCheckmark}
                  className={el`trusted-icon`}
                  tooltipContent="Trusted Contributor"
                />
              </InteractableComponent>
            )}
          </ResponsiveText>
          {p.joinedAt && (
            <Text type="body-sm" className={el`date-joined`}>
              Joined {format(p.joinedAt, 'MMM yyyy')}
            </Text>
          )}
          <SocialHandles className={el`social-handles`} username={p.username} />
        </div>
      </div>
    </div>
  );
}

type ProfileHeaderContainerProps = Pick<ProfileHeaderProps, 'username'>;

function ProfileHeaderContainer(
  props: ProfileHeaderContainerProps
): ReactElement {
  const { myProfile } = useMyProfile();
  const { url: newUrl, onSelectFile } = useUploadProfilePicture();
  const { user } = useProfile({ username: props.username });

  const isMe = myProfile?.username === props.username;
  const avatarSrc = isMe ? newUrl : user?.photo?.url;
  const joinedAt = user?.createdAt ? new Date(user.createdAt) : undefined;
  const trusted = user?.trusted ?? false;

  return (
    <ProfileHeader
      isMe={isMe}
      username={props.username}
      avatarSrc={avatarSrc}
      trusted={trusted}
      onSelectFile={onSelectFile}
      agreedToBeContacted={myProfile?.profile?.agreedToBeContacted ?? false}
      socialInfos={myProfile?.socialInfos ?? []}
      joinedAt={joinedAt && new Date(joinedAt)}
    />
  );
}

export default ProfileHeaderContainer;
