import { GetProfileQueryResult, useGetProfileQuery } from '@/generated/graphql';

type UseProfile = {
  user: NonNullable<GetProfileQueryResult['data']>['user'] | null;
  loading: boolean;
};

const useProfile = ({ username }: { username: string }): UseProfile => {
  const { data, loading } = useGetProfileQuery({
    variables: {
      input: {
        username,
      },
    },
  });
  return {
    user: data?.user ?? null,
    loading,
  };
};

export default useProfile;
