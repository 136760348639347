import React, { ReactElement } from 'react';
import { Desktop, Mobile, Tablet } from '@/utils/responsive';
import Text, { TextProps, TextType } from './Text';

type ResponsiveProps = {
  /**
   * The typography style to use on tablet and above,
   * if different than mobile.
   */
  tabletType?: TextType;

  /**
   * Whether the tablet style should be heavy styles, for body
   * typographies.
   * @default false;
   */
  tabletIsHeavy?: boolean;

  /**
   * The typography style to use on desktop and above,
   * if different than mobile.
   */
  desktopType?: TextType;

  /**
   * Whether the desktop style should be heavy styles, for body
   * typographies.
   * @default false;
   */
  desktopIsHeavy?: boolean;
};

export type ResponsiveTextProps = ResponsiveProps & TextProps;

const DEFAULT_RESPONSIVE_PROPS = {
  tabletIsHeavy: false,
  desktopIsHeavy: false,
};

const useResponsiveAndTextProps = (
  props: ResponsiveTextProps
): {
  textProps: TextProps;
  responsiveProps: ResponsiveTextProps;
  hasTabletTypeProp: boolean;
  hasDesktopTypeProp: boolean;
  hasBothResponsiveTypeProps: boolean;
} => {
  const {
    tabletType,
    tabletIsHeavy,
    desktopType,
    desktopIsHeavy,
    ...textProps
  } = props;

  const hasTabletTypeProp = tabletType !== undefined;
  const hasDesktopTypeProp = desktopType !== undefined;
  const hasBothResponsiveTypeProps = hasTabletTypeProp && hasDesktopTypeProp;

  return {
    textProps,
    responsiveProps: {
      tabletType,
      tabletIsHeavy,
      desktopType,
      desktopIsHeavy,
    },
    hasTabletTypeProp,
    hasDesktopTypeProp,
    hasBothResponsiveTypeProps,
  };
};

function ResponsiveTextComponent(props: ResponsiveTextProps): ReactElement {
  const {
    textProps,
    responsiveProps,
    hasTabletTypeProp,
    hasDesktopTypeProp,
    hasBothResponsiveTypeProps,
  } = useResponsiveAndTextProps(props);
  const p = {
    ...DEFAULT_RESPONSIVE_PROPS,
    ...responsiveProps,
  };

  const renderComponentForBreakpoint = (
    breakpoint?: 'mobile' | 'tablet' | 'desktop'
  ): ReactElement => {
    switch (breakpoint) {
      case 'tablet':
        return (
          <Text {...textProps} type={p.tabletType} isHeavy={p.tabletIsHeavy} />
        );
      case 'desktop':
        return (
          <Text
            {...textProps}
            type={p.desktopType}
            isHeavy={p.desktopIsHeavy}
          />
        );
      default:
        return <Text {...textProps} />;
    }
  };

  // @TODO use render prop of Media to put these text elements in fragments
  // rather than getting wrapped in fresnel div

  if (hasBothResponsiveTypeProps) {
    return (
      <>
        <Mobile>{renderComponentForBreakpoint()}</Mobile>
        <Tablet>{renderComponentForBreakpoint('tablet')}</Tablet>
        <Desktop>{renderComponentForBreakpoint('desktop')}</Desktop>
      </>
    );
  }

  if (hasTabletTypeProp) {
    return (
      <>
        <Mobile>{renderComponentForBreakpoint()}</Mobile>
        <Tablet>{renderComponentForBreakpoint('tablet')}</Tablet>
      </>
    );
  }

  if (hasDesktopTypeProp) {
    return (
      <>
        <Mobile>{renderComponentForBreakpoint()}</Mobile>
        <Desktop>{renderComponentForBreakpoint('desktop')}</Desktop>
      </>
    );
  }

  return <Text {...textProps} />;
}

/** @TODO forward ref support?  */
const ResponsiveText = ResponsiveTextComponent;

/** @WARNING experimental component */
export default ResponsiveText;
