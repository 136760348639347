import clsx from 'clsx';
import { useRouter } from 'next/router';
import { ReactElement, useEffect, useState } from 'react';
import {
  BlockExplorerLink,
  ChainIcon,
  ResponsiveAddress,
  Text,
} from '@/components';
import {
  OrganizationHeader,
  ProfileHeader,
  ProfileHeaderExtras,
  Search,
  SearchMyFeed,
  SubdomainHeader,
} from '@/features';
import { getDisplayStringForChainType } from '@/types/chain';
import { PageLoadingProps, PageProps, PageType } from '@/types/page';
import {
  getRouteForAddressSearch,
  getRouteForDomainSearch,
} from '@/types/routes';
import {
  getDescriptionForScamCategory,
  getDisplayStringForScamCategory,
} from '@/types/scam-categories';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import { Tablet } from '@/utils/responsive';
import { hasBlockExplorerPartner } from '@/types/block-explorer';

export type PageHeaderProps = StyleProps & (PageProps | PageLoadingProps);

const ROOT = makeRootClassName('PageHeader');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {} as const;

const CategoryIcon = (): ReactElement => (
  <svg viewBox="0 0 30 25" fill="none" className={el`category-icon`}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.6237 2.30798C15.5603 0.564008 13.8201 0.564008 12.7583 2.30798L1.46881 20.8289C0.40621 22.5729 1.21191 24 3.25966 24H26.1215C28.1693 24 28.975 22.5729 27.9117 20.8289L16.6237 2.30798Z"
      stroke="#E24B63"
      strokeWidth="1.27778"
    />
    <path d="M14.6898 6.90497V15.2508" stroke="#E24B63" strokeWidth="1.27778" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.7193 18.8558C15.7193 18.2896 15.2591 17.8317 14.6917 17.8317C14.1235 17.8317 13.6633 18.2896 13.6633 18.8558C13.6633 19.4213 14.1235 19.8799 14.6917 19.8799C15.2591 19.8799 15.7193 19.4213 15.7193 18.8558Z"
      stroke="#E24B63"
      strokeWidth="1.27778"
    />
  </svg>
);

function PageHeader(props: PageHeaderProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };
  const [showHeader, setShowHeader] = useState(false);
  const router = useRouter();

  /**
   * HACK: This component gets many of its props from the query params, which
   * are only available client side and not in the next SSR context. Therefore,
   * we use a useEffect hook to only render the component when the JS has loaded
   * client side and we can hydrate the page with the right props.
   *
   * Removing this results in SSR issues where the server thinks the page should
   * be rendered one way but the client later decides it should be rendered another
   * way.
   */
  useEffect(() => {
    setShowHeader(true);
  }, []);

  if (!showHeader) {
    return (
      <div className={clsx(ROOT, p.className)}>
        <div className={el`content`}>
          <div className={el`title-section`} />
        </div>
      </div>
    );
  }

  if ('isLoading' in p) {
    return <></>;
  }

  const hasAddressDomainSearch = [
    PageType.SEARCH_ADDRESS,
    PageType.SEARCH_DOMAIN,
    PageType.BROWSE_ALL,
    PageType.BROWSE_CATEGORY,
    PageType.BROWSE_CHAIN,
    PageType.REPORT_BY_ID,
    PageType.SUBDOMAIN_FEED,
    PageType.ABOUT,
    PageType.FAQ,
    PageType.GLOSSARY,
    PageType.CONTACT,
  ].includes(p.pageType);

  const hasTextSearch = [PageType.MY_FEED].includes(p.pageType);

  const renderPageTitle = () => {
    switch (p.pageType) {
      case PageType.SEARCH_ADDRESS:
      case PageType.SEARCH_DOMAIN:
        return (
          <>
            <Text type="body-sm" className={el`label`}>
              Reports submitted for
            </Text>
            {p.pageType === PageType.SEARCH_ADDRESS && (
              <div className={el`address-with-icon`}>
                <ChainIcon size="small" chainType={p.chain} />
                <ResponsiveAddress address={p.address} isHeavy />
                {p.chain && hasBlockExplorerPartner(p.chain) && (
                  <BlockExplorerLink address={p} />
                )}
              </div>
            )}
            {p.pageType === PageType.SEARCH_DOMAIN && (
              <Text type="h3" className={el`query`}>
                {p.domain}
              </Text>
            )}
          </>
        );
      case PageType.BROWSE_ALL:
        return <Text className={el`all-chain-title`}>Scam Reports</Text>;
      case PageType.BROWSE_CHAIN:
        return (
          <>
            <div className={el`title-with-icon`}>
              <ChainIcon size="large" chainType={p.chain} />
              <Text className={el`all-chain-title`}>
                {getDisplayStringForChainType(p.chain)} Scam Reports
              </Text>
            </div>
          </>
        );
      case PageType.BROWSE_CATEGORY:
        return (
          <>
            <CategoryIcon />
            <div className={el`title-with-description`}>
              <h2 className={el`title`}>
                {getDisplayStringForScamCategory(p.category)}
              </h2>
              <Text className={el`category-description`}>
                {getDescriptionForScamCategory(p.category)}
              </Text>
            </div>
          </>
        );
      case PageType.PROFILE:
      case PageType.PROFILE_SELF:
      case PageType.PROFILE_OTHER:
        return <ProfileHeader username={p.username} />;
      case PageType.MY_FEED:
        return <OrganizationHeader orgId={p.orgId} />;
      case PageType.SUBDOMAIN_FEED: {
        return <SubdomainHeader orgId={p.orgId} orgSlug={p.orgSlug} />;
      }
      case PageType.FILE_REPORT:
      case PageType.EDIT_REPORT:
        return (
          <div>
            <h2 className={el`title`}>Report a Scam</h2>
            <Text type="body-lg" className={el`header-description`}>
              Enter the details of your case and opt-in to receive support from
              Chainabuse.
              <br />
              The more information you provide, the better we can help you.
            </Text>
          </div>
        );
      case PageType.REPORT_BY_ID:
        return <h2 className={el`title`}>Scam Report</h2>;
      case PageType.ABOUT:
        return <h2 className={el`title`}>About Chainabuse</h2>;
      case PageType.FAQ:
        return <h2 className={el`title`}>Frequently Asked Questions</h2>;
      case PageType.CONTACT:
        return <h2 className={el`title`}>Contact us</h2>;
      case PageType.GLOSSARY:
        return <h2 className={el`title`}>Scam Glossary</h2>;
      case PageType.LEADERBOARD:
        return (
          <div className={el`leaderboard-wrapper`}>
            <h2 className={el`title`}>Leaderboard</h2>
            <Text className={el`leaderboard-description`}>
              Top contributors making web3 safer for all
            </Text>
          </div>
        );
      case PageType.SUPPORT_PARTNER_OPT_IN:
        return <></>;
    }
  };

  return (
    <div className={clsx(`${ROOT} type-${p.pageType}`, p.className)}>
      <div className={el`content`}>
        <div className={el`title-section`}>{renderPageTitle()}</div>
        {hasAddressDomainSearch && (
          <Tablet className={el`search-section`}>
            <Search
              onSearchAddress={(address, chain) =>
                router.push(getRouteForAddressSearch(address, chain))
              }
              onSearchDomain={(domain) =>
                router.push(getRouteForDomainSearch(domain))
              }
            />
          </Tablet>
        )}

        {hasTextSearch && (
          <div className={el`feed-search-section`}>
            <SearchMyFeed />
          </div>
        )}

        {p.pageType === PageType.PROFILE && (
          <ProfileHeaderExtras
            className={el`profile-extras`}
            username={p.username}
          />
        )}
      </div>
    </div>
  );
}

export default PageHeader;
