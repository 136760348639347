import clsx from 'clsx';
import { ReactElement, useState } from 'react';
import {
  createTelegram,
  svgDiscord,
  svgInstagram,
  svgTwitter,
} from '@/assets/icons';
import { Icon, Tooltip } from '@/components';
import { SocialServiceKind } from '@/generated/graphql';
import {
  buttonize,
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import useProfile from '../../hooks/useProfile';

export type SocialHandlesProps = StyleProps & {
  twitterHandle?: string;
  discordHandle?: string;
  telegramHandle?: string;
  instagramHandle?: string;
};

const TWITTER_ICON = svgTwitter;
const DISCORD_ICON = svgDiscord;
const TELEGRAM_ICON = createTelegram;
const INSTAGRAM_ICON = svgInstagram;
const TWITTER_ICON_VIEWBOX_WIDTH = 24;
const DISCORD_ICON_VIEWBOX_WIDTH = 71;
const DISCORD_ICON_VIEWBOX_HEIGHT = 55;

const ROOT = makeRootClassName('SocialHandles');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {} as const;

export function SocialHandles(props: SocialHandlesProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const handleCopy = (textToCopy: string) => {
    navigator.clipboard.writeText(textToCopy);
    setIsTooltipOpen(true);
    setTimeout(() => {
      setIsTooltipOpen(false);
    }, 1000);
  };

  return (
    <div className={clsx(ROOT, p.className)}>
      {p.twitterHandle && (
        <a
          href={`https://twitter.com/${p.twitterHandle}`}
          target="_blank"
          rel="noreferrer"
        >
          <div className={el`handle`}>
            <Icon
              content={TWITTER_ICON}
              viewBoxWidth={TWITTER_ICON_VIEWBOX_WIDTH}
              tooltipContent={p.twitterHandle}
              className={el`icon`}
            />
          </div>
        </a>
      )}

      {p.discordHandle && (
        <Tooltip content="Copied" isInstant open={isTooltipOpen}>
          <div
            {...buttonize(() => handleCopy(p.discordHandle || ''))}
            className={el`handle`}
          >
            <Icon
              content={DISCORD_ICON}
              viewBoxHeight={DISCORD_ICON_VIEWBOX_HEIGHT}
              viewBoxWidth={DISCORD_ICON_VIEWBOX_WIDTH}
              tooltipContent={p.discordHandle}
              className={el`icon`}
            />
          </div>
        </Tooltip>
      )}

      {p.telegramHandle && (
        <a
          href={`https://t.me/${p.telegramHandle}`}
          target="_blank"
          rel="noreferrer"
        >
          <div className={el`handle`}>
            <Icon
              content={TELEGRAM_ICON}
              tooltipContent={p.telegramHandle}
              className={el`icon`}
            />
          </div>
        </a>
      )}

      {p.instagramHandle && (
        <a
          href={`https://instagram.com/${p.instagramHandle}`}
          target="_blank"
          rel="noreferrer"
        >
          <div className={el`handle`}>
            <Icon
              content={INSTAGRAM_ICON}
              tooltipContent={p.instagramHandle}
              className={el`icon`}
            />
          </div>
        </a>
      )}
    </div>
  );
}

type SocialHandlesContainer = StyleProps & {
  username: string;
};

function SocialHandlesContainer(props: SocialHandlesContainer): ReactElement {
  const { user } = useProfile({ username: props.username });

  const twitterHandle = user?.socialInfos.find(
    (i) => i.service === SocialServiceKind.TWITTER
  )?.handle;

  const discordHandle = user?.socialInfos.find(
    (i) => i.service === SocialServiceKind.DISCORD
  )?.handle;

  const telegramHandle = user?.socialInfos.find(
    (i) => i.service === SocialServiceKind.TELEGRAM
  )?.handle;

  const instagramHandle = user?.socialInfos.find(
    (i) => i.service === SocialServiceKind.INSTAGRAM
  )?.handle;

  return (
    <SocialHandles
      className={props.className}
      twitterHandle={twitterHandle}
      discordHandle={discordHandle}
      telegramHandle={telegramHandle}
      instagramHandle={instagramHandle}
    />
  );
}
export default SocialHandlesContainer;
