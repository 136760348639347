import React, { ReactElement } from 'react';
import { SearchField } from '@/components';
import { useDebounce } from '@/hooks';
import { useMyFeed } from '@/providers';

export default function SearchMyField(): ReactElement {
  const {
    actions: { setSearchQuery },
  } = useMyFeed();

  const handleChange = useDebounce({
    fn: setSearchQuery,
    delay: 500,
  });

  return (
    <SearchField
      placeholder="Search My Feed"
      aria-label="Search My Feed"
      onChange={handleChange}
    />
  );
}
