import clsx from 'clsx';
import React, { ReactElement } from 'react';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import { Text } from '@/components';
import { useMe } from '@/hooks';

const ROOT = makeRootClassName('OrganizationHeader');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {} as const;

export type OrganizationHeaderProps = StyleProps & {
  name: string;
  keywords?: string[];
};

export function OrganizationHeader(
  props: OrganizationHeaderProps
): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  return (
    <div className={clsx(ROOT, p.className)}>
      <Text type="h4" className="text-trm-blue-2">
        My Feed
      </Text>

      <Text type="h1" className="text-trm-gray-4">
        {p.name}
      </Text>

      {p.keywords && p.keywords.length > 0 && (
        <Text className="text-trm-gray-1">
          Keywords: {p.keywords.join(', ')}
        </Text>
      )}
    </div>
  );
}

type OrganizationHeaderContainerProps = {
  orgId: string;
};

function OrganizationHeaderContainer(
  props: OrganizationHeaderContainerProps
): ReactElement {
  const { me, loading } = useMe();

  if (loading) {
    return <></>;
  }
  const organization = me?.organizations.find((o) => o.id === props.orgId);

  return (
    <OrganizationHeader
      name={organization?.name ?? ''}
      keywords={organization?.keywords}
    />
  );
}
export default OrganizationHeaderContainer;
