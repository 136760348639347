import clsx from 'clsx';
import { ChangeEvent, ReactElement, useRef, useState } from 'react';
import { createExport } from '@/assets/icons';
import { Button } from '@/components';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  useTooltipComponentProps,
} from '@/utils';
import { Avatar, Modal, Text } from '..';
import { AvatarProps } from '../avatar/Avatar';
import { ModalRootProps } from '../modal';
import { OptionalTooltip } from '../tooltip/Tooltip';

type UploadAvatarSizeErrorModalProps = Pick<
  ModalRootProps,
  'open' | 'onOpenChange'
> & {
  /**
   * Size limit to show in error message.
   */
  sizeLimit: number;
};

const UploadAvatarSizeErrorModal = (
  props: UploadAvatarSizeErrorModalProps
): ReactElement => {
  return (
    <Modal.Root open={props.open} onOpenChange={props.onOpenChange}>
      <Modal.Content>
        <Modal.Header hasCloseButton className={el`modal-header`} />
        <div className={el`modal-content`}>
          <Text>
            Profile picture can be .jpg or .png and maximum size of{' '}
            {props.sizeLimit / 1000}kb.
          </Text>
          <Text>Please try again.</Text>
        </div>
      </Modal.Content>
    </Modal.Root>
  );
};

const UPLOAD_ICON = createExport;

export type UploadAvatarProps = AvatarProps & {
  /**
   * Handler to call when the user chooses a new avatar.
   */
  onSelectFile?: (file: File) => void;

  /**
   * The avatar's size limit in bytes.
   * @default 1000000 (1 MB)
   */
  sizeLimit?: number;

  /**
   * Whether the avatar is circular or square.
   * @default 'circular'
   */
  shape?: 'circle' | 'square';

  /**
   * Whether the upload CTA is shown on hover tooltip or always
   * below the avatar as a button.
   * @default 'tooltip'
   */
  uploadCTAStyle?: 'tooltip' | 'button';
};

const ROOT = makeRootClassName('UploadAvatar');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {
  sizeLimit: 1000000,
  shape: 'circle',
  uploadCTAStyle: 'tooltip',
} as const;

function UploadAvatar(props: UploadAvatarProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };
  const [isFileInvalid, setIsFileInvalid] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const tooltipProps = useTooltipComponentProps(p);

  const handleUploadButtonPress = (): void => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileInput = (event: ChangeEvent) => {
    const target = event.target as HTMLInputElement;
    const image = (target.files as FileList)[0];

    if (image.size > p.sizeLimit) {
      setIsFileInvalid(true);
      return;
    }

    p.onSelectFile?.(image);
  };

  const isTooltipCTA = p.uploadCTAStyle === 'tooltip';

  return (
    <div className={clsx(`${ROOT} cta-${p.uploadCTAStyle} shape-${p.shape}`)}>
      <OptionalTooltip
        {...tooltipProps}
        content={isTooltipCTA ? 'Upload / Edit Profile Picture' : undefined}
        isInstant
        side="top"
      >
        <div>
          <input
            id="profile-picture"
            ref={fileInputRef}
            type="file"
            accept="image/jpeg, image/png"
            className="hidden cursor-pointer"
            onChange={handleFileInput}
          />
          <label htmlFor="profile-picture">
            <Avatar
              image={p.image}
              size={p.size}
              hoverIcon={isTooltipCTA ? UPLOAD_ICON : undefined}
              isButton={isTooltipCTA}
              shape={p.shape}
              className={p.className}
            />
          </label>
          {!isTooltipCTA && (
            <label htmlFor="profile-picture">
              <Button
                onPress={handleUploadButtonPress}
                startIcon={UPLOAD_ICON}
                variant="text-white"
              >
                Upload new
              </Button>
            </label>
          )}
        </div>
      </OptionalTooltip>
      <UploadAvatarSizeErrorModal
        open={isFileInvalid}
        onOpenChange={setIsFileInvalid}
        sizeLimit={p.sizeLimit}
      />
    </div>
  );
}

export default UploadAvatar;
