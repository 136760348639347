import clsx from 'clsx';
import { queryTypes, useQueryState } from 'next-usequerystate';
import { useRouter } from 'next/router';
import { ReactElement } from 'react';
import {
  getPagePropsFromDynamicRoute,
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import { QueryParamNames } from '@/types/routes';
import { PageType } from '@/types/page';
import { PageHeader } from '../page-header';
import { getLayout as getSiteLayout } from '../site-layout';

export type PageLayoutProps = StyleProps & {
  pageType: PageType;
  children: ReactElement;
};

const ROOT = makeRootClassName('PageLayout');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {} as const;

function PageLayout(props: PageLayoutProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };
  const { query } = useRouter();

  // only used in the case of /report/:reportId?context=:context&context_props=...
  const [context] = useQueryState(
    QueryParamNames.PAGE_CONTEXT,
    queryTypes.stringEnum<PageType>(Object.values(PageType))
  );

  const pageProps = getPagePropsFromDynamicRoute({
    pageType: p.pageType,
    query,
    context: context ?? undefined,
  });

  return (
    <div className={clsx(ROOT, p.className)}>
      <PageHeader {...pageProps} />
      <div className={el`content`}>{p.children}</div>
    </div>
  );
}

export const getLayout = (
  page: ReactElement,
  pageType: PageType
): ReactElement => {
  return getSiteLayout(
    <PageLayout pageType={pageType}>{page}</PageLayout>,
    pageType
  );
};

export default PageLayout;
