import clsx from 'clsx';
import { ReactElement } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { CookieConsentBanner, ErrorPage } from '@/features';
import { PageType } from '@/types/page';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import { Desktop } from '@/utils/responsive';
import { Footer } from '../footer';
import { Header } from '../header';
import { SecondTierNav } from '../second-tier-nav';

export type SiteLayoutProps = StyleProps & {
  children: ReactElement;

  /**
   * The page type the layout is being rendered on.
   * Optional unless you need the visual style of the layout to
   * change based on the selected page.
   */
  pageType?: PageType;
};

const ROOT = makeRootClassName('SiteLayout');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {} as const;

function SiteLayout(props: SiteLayoutProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };
  const pageType = p.pageType;

  return (
    <div className={clsx(ROOT, p.className)}>
      <ErrorBoundary FallbackComponent={ErrorPage}>
        <Desktop>
          <SecondTierNav pageType={p.pageType} />
        </Desktop>
        <Header pageType={p.pageType} />
        <div className={clsx(el`content`, {
          'is-home': pageType === PageType.HOME,
        })}>{p.children}</div>
        <Footer pageType={p.pageType} />
        <CookieConsentBanner />
      </ErrorBoundary>
    </div>
  );
}

export const getLayout = (
  page: ReactElement,
  pageType?: PageType
): ReactElement => <SiteLayout pageType={pageType}>{page}</SiteLayout>;

export default SiteLayout;
