import clsx from 'clsx';
import { useRouter } from 'next/router';
import React, { ReactElement, useEffect, useState } from 'react';
import { Button, ClientOnly, Link, Text } from '@/components';
import { useCookieConsent } from '@/providers';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import { PRIVACY_POLICY_URL } from '@/types/routes';

export type CookieConsentBannerProps = StyleProps & {
  // add props here
};

const ROOT = makeRootClassName('CookieConsentBanner');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {} as const;

function CookieConsentBanner(props: CookieConsentBannerProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  const { state, actions } = useCookieConsent();
  const [isClosed, setIsClosed] = useState(false);
  const { pathname } = useRouter();

  const handleAccept = () => {
    actions.setIsAccepted(true);
  };

  const handleClose = () => {
    setIsClosed(true);
  };

  useEffect(() => {
    // persist banner across pages
    setIsClosed(false);
  }, [pathname]);

  if (state.isAccepted || isClosed) return <></>;

  return (
    <ClientOnly>
      <div className={clsx(ROOT, p.className)}>
        <div className={el`content`}>
          <Text>
            By clicking{' '}
            <Text as="span" isHeavy>
              “Accept”
            </Text>
            , you agree to the storing of cookies on your device to enhance site
            navigation, analyze site usage, and assist in our marketing efforts.
            View our{' '}
            <Link href={PRIVACY_POLICY_URL} isExternal>
              Privacy Policy
            </Link>{' '}
            for more information.
          </Text>
          <div className={el`actions`}>
            <Button onPress={handleAccept}>Accept</Button>
            <Button variant="text-blue" onPress={handleClose}>
              Close
            </Button>
          </div>
        </div>
      </div>
    </ClientOnly>
  );
}

export default CookieConsentBanner;
